import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./SecondStepJobListing.scss";
import { PayOptionConstant } from "../../../constants/JobConstant";
import { JobOfferingContext } from "../../../context/JobOfferringContext";
import FireTracking from "../../../assets/FireTracking/fireTracking";
import { TYPE_TRACKING } from "../../../constants/TrackingConstant";
import { JobApi } from "../../../api/jobApi";
import DefaultImage from "../../../assets/images/building.jpg";
import useQueryHook from "../../../customHooks/useQueryHook";
import axios from 'axios';

const SecondStepJobListing = () => {
  const baseSerpUrl = process.env.REACT_APP_SERP_BASE_URL;

  const { jobs, setJobs, payOutRev } = useContext(JobOfferingContext);

  const { queryString, queryParams, pixelFire, skipPixelFIre } = useQueryHook();
  const keyword = queryParams.get("keyword") || queryParams.get("query");
  const campaignId = queryParams.get("camp_id");
  const subId = queryParams.get("sub_id") || queryParams.get("subid");
  const source = queryParams.get("source");
  const [currentJobIndex, setCurrentJobIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [skipCount, setSkipCount] = useState(0);
  const [tinyJobsArray, setTinyJobsArray] = useState([]);
  const params = JSON.parse(localStorage.getItem('job-genius-user-info'));
  const currentJob = jobs[currentJobIndex];
  let userLocation = useRef("");
  let userState = useRef("");
  let userZip = useRef('');
  const isTesting = queryParams.get('testing');
  const isTesting2 = queryParams.get('testing2');
  const isTesting3 = queryParams.get('testing3');
  const jgcid = queryParams.get('jgcid');
  const [logosMap, setLogosMap] = useState({});
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [normalJobs, setNormalJobs]= useState([]);
  const [specificJobs,setSpecificJobs]= useState([]);
  const [talentApiJobs,setTalentApiJobs]= useState([]);
  const [talrooApiJobs,setTalrooApiJobs]= useState([]);
  const userAgent= navigator.userAgent; 
  const isTalentAPIEnable = process.env.REACT_APP_TALENT_API_ENABLE;
  const MILES_TO_KILOMETERS = 1.609344;

  const testObj = useMemo(() => {

    return {
      keyword,
      campaignId,
      subId,
      source,
      testing: isTesting || false,
      testing2: isTesting2 || false,
      testing3: isTesting3 || false,
      page: 0,
      size: 6, // Assuming up to 6 jobs
    };
  }, [keyword, campaignId, isTesting, subId]);

  const redirectToSERP = () => {
    let locationParams = "";
    if (userLocation.current && userState.current) {
      locationParams = `${userLocation.current},${userState.current}`; // Both city and state are present
    } else if (userLocation.current) {
      locationParams = `${userLocation.current}`; // Only city is present
    } else if (userState.current) {
      locationParams = `${userState.current}`; // Only state is present
    }
    window.location.href = `${baseSerpUrl}/${queryString || "?"}&query=${keyword || ""
    }&job_location=${locationParams}&zip=${userZip.current}`;
  };

  const totalJobs = useMemo(() => {
    const firstThreeJobs = jobs.slice(0, 3).map((job) => job.jobId); // Extract job IDs from the first 3 jobs.
    const filteredTinyJobs = tinyJobsArray.filter(
      (tinyJob) => !firstThreeJobs.includes(tinyJob.jobId) // Exclude jobs already in 'jobs.slice(0, 3)'.
    );

    // Combine the first 3 from 'jobs' and next 3 filtered jobs from 'tinyJobsArray'.
    return [
      ...jobs.slice(0, 3),
      ...filteredTinyJobs.slice(0, 3),
    ];
  }, [jobs, tinyJobsArray]);

  const handleSkip = async (currJob) => {
    setIsDisabledBtn(true);
    const dataTracking = {
      jobId: currJob?.jobId,
      majorCategory: currJob?.majorCategory,
      conversion: 0,
    };
    if(currJob?.apiJob){
      pixelFire("apiskip");
    }else{
      FireTracking(TYPE_TRACKING.applyJob, dataTracking);
    }
    skipPixelFIre(currentJobIndex + 1);
    if (currentJobIndex >= 5 || currentJobIndex >= jobs.length - 1) {
      pixelFire("eof");
      redirectToSERP();
      return;
    }

    if (skipCount >= 2) {
      // Show FixedTinyJobArray after 3 skips, limited to 6 jobs total
      if (tinyJobsArray.length > 0) {
        const tinyJobs = tinyJobsArray.slice(0, 5 - currentJobIndex);
        const remainingJobs = jobs.slice(0, currentJobIndex + 1)
        setJobs([...remainingJobs, ...tinyJobs]);
      }
      setCurrentJobIndex((prevIndex) => prevIndex + 1);
      setIsDisabledBtn(false);
      setSkipCount(0);
    } else {
      setCurrentJobIndex((prevIndex) => prevIndex + 1);
      setIsDisabledBtn(false);
      setSkipCount((prev) => prev + 1);
    }

    const nextJobIndex = currentJobIndex + 2;
    if (nextJobIndex < totalJobs.length) {
      const nextJob = totalJobs[nextJobIndex];
      fetchJobLogo(nextJob);
    }
  };
  
  const handleApply = async (currJob) => {
    setIsDisabledBtn(true);
    try {
      setSkipCount(0);
      // Implement logic for pixel fire
      const dataTracking = {
        jobId: currJob?.jobId,
        majorCategory: currJob?.majorCategory,
        conversion: 1,
        keyword,
        inboundUrl: document.referrer + queryString,
        jgcid,
        subid:subId,
        source
      };
      payOutRev.current = payOutRev.current + currJob.bid;
      setCurrentJobIndex((prevIndex) =>
        Math.min(prevIndex + 1, jobs.length - 1)
      );

      if (currJob?.apiJob) {
        pixelFire("apiclick");
        const windowAddress = currJob.url;
        window.open(windowAddress);
      } else {
        FireTracking(TYPE_TRACKING.applyJob, dataTracking);

        const windowAddress = params
          ? `${currJob.shaUrl}${
              queryString
                ? `${queryString}&first_name=${params.firstName}&last_name=${params.lastName}&email=${params.email}&phone=${params.phoneNumber}&zip=${userZip.current}`
                : `?first_name=${params.firstName}&last_name=${params.lastName}&email=${params.email}&phone=${params.phoneNumber}&zip=${userZip.current}`
            }`
          : `${currJob.shaUrl}${queryString || ""}`;
        window.open(windowAddress);
      }

      if (currentJobIndex >= 5 || currentJobIndex >= jobs.length - 1) {
        pixelFire("eof");
        redirectToSERP();
      }
      setIsDisabledBtn(false);
    } catch (error) {
      console.log("Error while firing apply event", error);
      setIsDisabledBtn(false);
    }

    const nextJobIndex = currentJobIndex + 2;
    if (nextJobIndex < jobs.length) {
      const nextJob = jobs[nextJobIndex];
      fetchJobLogo(nextJob);
    }
    
  };
  const hitApi = async (jobs) => {
    const talentApiJobs = jobs.filter(job => job.apiUrl?.startsWith('talentApi:'));
    const talrooApiJobs = jobs.filter(job => job.apiUrl?.startsWith('talrooApi:'));
  
    const apis = [];
    
    if (talentApiJobs.length > 0 && isTalentAPIEnable) {
      apis.push(handleTalentApiJob(talentApiJobs));
    }
  
    if (talrooApiJobs.length > 0) {
      apis.push(handleTalrooApiJob(talrooApiJobs));
    }
  
    await Promise.allSettled(apis);
  };

 const handleTalentApiJob = async (talentjobs) => {
   let params = [
     keyword && `k=${encodeURIComponent(keyword)}`,
     (talentjobs[0]?.city) ? `l=${encodeURIComponent(talentjobs[0].city)}` : talentjobs[0]?.state && `l=${encodeURIComponent(talentjobs[0].state)}`,
     talentjobs[0]?.country && `country=${encodeURIComponent(talentjobs[0].country)}`,
     userAgent && `useragent=${encodeURIComponent(userAgent)}`,
     talentjobs[0]?.ip && `ip=${encodeURIComponent(talentjobs[0].ip)}`,
     talentjobs[0]?.distance &&
       `radius=${encodeURIComponent(Number(talentjobs[0].distance) * MILES_TO_KILOMETERS)}`,
     "format=json",
     `limit=${encodeURIComponent(talentjobs.length)}`,
     "backfill=0",
   ];
   const apiUrl = `${talentjobs[0].apiUrl.replace("talentApi:", "").trim()}&${params
     .filter(Boolean)
     .join("&")}`;
   try {
     const apiResponse = await axios.get(apiUrl);
     const fetchedJobs = apiResponse?.data?.data?.jobs;
     const updatedJobs = talentjobs
     .map((job, index) => {
       const fetchedJob = fetchedJobs[index];
       return fetchedJob ? { ...job, ...fetchedJob } : null;
     })
     .filter((job) => job !== null);
     setTalentApiJobs(updatedJobs);
   } catch (error) {
    console.error("Error fetching Talent API:", error);
   }
 };

 const handleTalrooApiJob = async (talroojobs) => {
   let params = [
     keyword && `q=${encodeURIComponent(keyword)}`,
     (talroojobs[0]?.city && talroojobs[0].state)? `l=${encodeURIComponent(`${talroojobs[0].city},${talroojobs[0].state}`)}`
     : talroojobs[0]?.zip && `l=${encodeURIComponent(talroojobs[0].zip)}`,
     talroojobs[0]?.ip && `ip=${encodeURIComponent(talroojobs[0].ip)}`,
     talroojobs[0]?.distance &&
       `d=${encodeURIComponent(Number(talroojobs[0].distance) * MILES_TO_KILOMETERS)}`,
     "format=json",
     "logo=1",
     "link=1",
     `limit=${encodeURIComponent(talroojobs.length)}`,
   ];

   const apiUrl = `${talroojobs[0].apiUrl.replace("talrooApi:", "").trim()}&${params
     .filter(Boolean)
     .join("&")}`;
   try {
     const apiResponse = await axios.get(apiUrl);
     const fetchedJobs=apiResponse?.data?.jobs;
     const updatedJobs = talroojobs
     .map((job, index) => {
       const fetchedJob = fetchedJobs[index];
       return fetchedJob ? { ...job, ...fetchedJob } : null;
     })
     .filter((job) => job !== null);
     setTalrooApiJobs(updatedJobs);
   } catch (error) {
      console.error("Error fetching Talroo API:", error);
   }
 };
 useEffect(() => {
   const slottingJobs = [...specificJobs, ...talrooApiJobs, ...talentApiJobs];
   const jobsWithSpecificData = slotJobs(slottingJobs, normalJobs);
   setJobs(jobsWithSpecificData);
 }, [talentApiJobs, talrooApiJobs, normalJobs, specificJobs]);

 const slotJobs = (specificJobs, combinedJobs) => {
  const jobsWithSpecificData = Array(6).fill(null);

  // Place specific jobs into their slots
  specificJobs.forEach((specificJob) => {
    const slot = specificJob.slot - 1;
    if (slot >= 0 && slot < jobsWithSpecificData.length) {
      jobsWithSpecificData[slot] = specificJob;
    }
  });

  // Fill remaining slots with combined jobs
  let combinedIndex = 0;
  for (let i = 0; i < jobsWithSpecificData.length; i++) {
    if (!jobsWithSpecificData[i] && combinedIndex < combinedJobs.length) {
      jobsWithSpecificData[i] = combinedJobs[combinedIndex];
      combinedIndex++;
    }
  }

  return jobsWithSpecificData.filter((job) => job !== null);
};
 
  const fetchJobLogo = (job) => {
    if (!job) return;
  
    const companyLogo =
      job?.jobType === 1
        ? keyword || job?.importCompanyName || job?.companyName || job?.company
        : job?.importCompanyName || job?.companyName || job?.company;
    if(companyLogo){
      JobApi.fetchLogo(companyLogo)
      .then((logoResponse) => {
        const logo = logoResponse?.data?.[0]?.image || job?.companyLogo || job?.logo_url || job?.logo || DefaultImage;

        setLogosMap((prevLogosMap) => ({
          ...prevLogosMap,
          [job.jobId]: logo,
        }));
      })
      .catch((error) => {
        console.error("Error fetching logo for company:", error);

        setLogosMap((prevLogosMap) => ({
          ...prevLogosMap,
          [job.jobId]: job?.companyLogo || job?.logo_url || DefaultImage,
        }));
      });
    }
  };
   
  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      // Run both API calls in parallel
      const jobDataResponse = await JobApi.getJobApi({ ...testObj });
      const specificJobData = jobDataResponse?.data?.slottingJobResult?.content || [];
      const jobData = jobDataResponse?.data?.normalJobResult?.content || [];
      const tinyJobData = jobDataResponse?.data?.tinyJobResult?.content || [];
      userLocation.current = jobDataResponse?.data?.userCity || "";
      userState.current = jobDataResponse?.data?.userState || "";
      userZip.current = jobDataResponse?.data?.userZip || "";
      // If fetchedJobs are less than 6, concat with tinyJobData
      const combinedJobs = jobData.length < 6
          ? [...jobData, ...tinyJobData.slice(0, 6 - jobData.length)]
          : [...jobData];

      const jobsWithSpecificData = slotJobs(specificJobData, combinedJobs);

      setTinyJobsArray(tinyJobData);
      setSpecificJobs(specificJobData.filter(job => !job.apiUrl?.startsWith('talrooApi:')));
      setNormalJobs(combinedJobs);
      const jobsToPrefetch = jobsWithSpecificData.slice(0, 2);
      await Promise.allSettled(jobsToPrefetch.map(fetchJobLogo));
      await hitApi(jobsWithSpecificData);
    } catch (err) {
      console.error("Error fetching job data:", err);
    } finally {
      setLoading(false);
    }
  }, [testObj, setJobs]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [testObj]);
  useEffect(()=>{
    if(currentJob){
      const dataTracking = {
        jobId: currentJob?.jobId,
        majorCategory: currentJob?.majorCategory,
        conversion: 0,
        keyword,
        inboundUrl: document.referrer + queryString,
        jgcid,
        subid:subId,
        source,
        impression: 1
      };
      FireTracking(TYPE_TRACKING.displayJob, dataTracking);
    }
  },[currentJob])
  return (
    <div>
      {loading ? (
        <div className="loading-spinner">
          <p>Loading...</p>
        </div>
      ) : currentJob ? (
        <div className="job-browser">
          <div className="company-image">
            <img
              src={currentJob.logo_url || currentJob.logo || logosMap[currentJob.jobId] || currentJob.companyLogo || DefaultImage} 
              alt="companyLogo"
              loading="lazy"
            />
          </div>
          <div className="job-listing">
            {currentJob.jobType ? (
              <p className="job">
                {currentJob.titleOverRide === 0 ?
                  `${currentJob.title}` :
                   `Apply for ${keyword ? keyword : ''} Jobs in ${userLocation.current || 'global'}`
                   }
              </p>
            ) : (
              <p className="job">
                {`${currentJob.title || currentJob.jobtitle} in ${currentJob.postalCode || "global"}${(currentJob.payTo || currentJob.payFrom) ? `up to $${currentJob.payFrom || currentJob.payTo}/${PayOptionConstant[currentJob.payOption] || ""}` : ""}`}
              </p>
            )}
          </div>
          <hr />
          <div className="footer-job-button">
            <button
              className="view-job"
              onClick={() => handleApply(currentJob)}
              disabled={isDisabledBtn}
            >
              Apply
            </button>
            <button className="skip-job" onClick={() => handleSkip(currentJob)}  disabled={isDisabledBtn}>
              Skip
            </button>
          </div>
        </div>
      ) : (
        <p>No more jobs to show</p>
      )}
    </div>
  );
};

export default SecondStepJobListing;
